<template>
    <modal ref="modalCrearTema" titulo="Crear Tema" tamano="modal-lg" @guardar="crearTema">
        <div class="row mx-0">
            <div class="col">
                <el-input v-model="model.nombre" class="w-100 input-name mt-2" placeholder="Nombre del Tema" maxlength="120" />
                <p class="f-15 text-gris my-2 ml-2 text-center">
                    Contenido
                </p>
                <div class="col">
                    <quill-editor
                    ref="editor"
                    v-model="contenido"
                    class="quill-radius"
                    :options="editorOption"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'
export default {
    data(){
        return {
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],

                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction

                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],

                        ['clean']                                         // remove formatting button
                    ]
                },
            },
            model: {
                nombre: '',
                contenido:''
            },
            contenido:'',
            editorOption:{
                placeholder: 'Ingrese el texto aqui...'
            }
            
        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso)
        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalCrearTema.toggle();
        },
        async crearTema(){
            try {

                if(this.model.nombre == '' || this.contenido == ''){
                    this.notify('Alerta, Los campos son requeridos','warning')
                    return
                }

                this.model.contenido = this.contenido
                const {data} = await Cursos.crearTema(this.id_curso, this.model)
                this.notify('Tema almacenado correctamente','success')
                this.$emit('update')
                this.$refs.modalCrearTema.toggle();

            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.model.nombre = ''
            this.model.contenido = ''
            this.contenido = ''
        }


    },
}
</script>

<style lang="scss" scoped>
</style>