import { render, staticRenderFns } from "./modalCrearTema.vue?vue&type=template&id=ba51e060&scoped=true&"
import script from "./modalCrearTema.vue?vue&type=script&lang=js&"
export * from "./modalCrearTema.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba51e060",
  null
  
)

export default component.exports